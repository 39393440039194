import axios from 'axios';
import Vue from 'vue'
import { Toast } from 'vant';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 10000
})

service.interceptors.request.use((config) => {
    config.headers.SKLM_WX_USER_TOKEN = localStorage.getItem('SKLM_WX_USER_TOKEN')
    return config;
});

service.interceptors.response.use(
    (response) => {
        if (response.data.code == 401) {
            console.log("无权限,非法操作")
            Toast('无权限,非法操作');
        }
        if (response.status == 200) {
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response.data);
        }
    },
    (error) => {
        Vue.prototype.$message({
            message: error.response.data.message,
            type: 'error',
            duration: 1000
        })
        return Promise.reject(error);
    }
);

export default service
