import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import BannerInfo from "../views/BannerInfo.vue";
const routes = [
    {
        path: "/",
        name: "Home",
        meta:{title:"首页"},
        component: () =>
            import("../views/Home.vue"),
    },
    {
        path: "/collarVideo",
        name: "collarVideo",
        meta:{title:"如何领取视频"},
        component: () =>
            import("../views/collarVideo.vue"),
    },
    {
        path: "/bannerInfo",
        name: "BannerInfo",
        meta:{title:""},
        component: BannerInfo,
    },
    {
        path: "/agreement",
        name: "Agreement",
        meta:{title:"用户协议及隐私政策"},
        component: () =>
            import("../views/Agreement.vue"),
    },
    {
        path: "/schema",
        name: "schema",
        meta:{title:"schema"},
        component: () =>
            import("../views/Schema.vue"),
    },
    {
        path: "/margin_rule",
        name: "margin_rule",
        meta:{title:""},
        component: () =>
            import("../views/MarginRule.vue"),
    },
    {
        path: "/margin_rule_ip",
        name: "margin_rule_ip",
        meta:{title:""},
        component: () =>
            import("../views/MarginRuleIp.vue"),
    },
    {
        path: "/trill_auth",
        name: "trillAuth",
        meta:{title:"TrillAuth"},
        component: () =>
            import("../views/trillAuth1.vue"),
    },
    {
        path: "/trill_success",
        name: "trillSuccess",
        meta:{title:"TrillSuccess"},
        component: () =>
            import("../views/trillSuccess.vue"),
    },
    {
        path: "/trill_fail",
        name: "TrillFail",
        meta:{title:"trillFail"},
        component: () =>
            import("../views/trillFail.vue"),
    },
    {
        path: "/trill_lock",
        name: "TrillLock",
        meta:{title:"trillLock"},
        component: () =>
            import("../views/trillLock.vue"),
    },
    {
        path: "/trill_tip",
        name: "TrillTip",
        meta:{title:"提示页"},
        component: () =>
            import("../views/trillTip.vue"),
    },
];


const index = createRouter({
    history: process.env.VUE_APP_MODE === 'production' ? createWebHistory() : createWebHashHistory('/mobile'),
    routes,
});

index.beforeEach((to,from,next)=>{
    if(to.meta.title){//判断是否有标题
        document.title = to.meta.title
    }
    next()
})



export default index;
