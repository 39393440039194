import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import Vant from 'vant';
import VConsole from 'vconsole';
import 'vant/lib/index.css';
import BrowserLogger from 'alife-logger'

const __bl = BrowserLogger.singleton({
    pid: "c9webccuyj@62537a3013d9843",
    appType: "web",
    imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    enableConsole: true
});

if (process.env.VUE_APP_MODE == "test") {
    // eslint-disable-next-line no-unused-vars
    const vConsole = new VConsole();
}
const app = createApp(App);
app.config.globalProperties.$bl = __bl;
app.use(router);
app.use(Vant);
app.mount("#app");
