<template>
  <div class="html_content" v-html="bannerInfo.content" />
</template>

<script>
import { bannerInfo } from '../api/bannerInfo'
import { Toast } from 'vant';

export default {
  name: "BannerInfo",
  data(){
    return{
      bannerInfo:{}
    }
  },
  methods:{
    initData(bannerId) {
      bannerInfo({id:bannerId}).then((res) => {
        if(res.status == 1){
            document.title = res.data.page_name
            this.bannerInfo = res.data
        }else{
          Toast("加载失败!" + bannerId);
        }
      });
    },
  },
  mounted(){
    let query = this.$route.query;
    console.log(JSON.stringify(query))
    let SKLM_WX_USER_TOKEN = this.$route.query.sklmWxUserToken
    localStorage.setItem("SKLM_WX_USER_TOKEN",SKLM_WX_USER_TOKEN)
    let bannerId = this.$route.query.bannerId
    this.initData(bannerId);
  },
}
</script>

<style scoped>
  .html_content{
    padding: 2%;
  }
</style>
